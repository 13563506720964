<template>
  <div>
    <div class="supplier-menu">
      <div class="supplier-menu-main">
        <div class="content">
          <a
            :href="item.url"
            v-for="item in menuList"
            :key="item.id"
            :class="['item', item.id == active ? 'on' : '']"
          >
            {{ item.name }}
          </a>
        </div>
      </div>
    </div>
   
  </div>
</template>
<script>
export default {
  props: {
    active: {
      default: 1,
      type: Number,
    },
    banner: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      menuList: [
        // { id: 1, name: "在线制造平台", url: "/make" },
        // { id: '0', name: "全部", url: "/supplier/list?cmsHelpCategoryId=004" ,cindex:"004"},
        { id: '1', name: "展会资讯", url: "/match/list?cmsHelpCategoryId=1656455518364344322001" ,cindex:"1656455518364344322001"},
        { id: '2', name: "赛事资讯", url: "/match/list?cmsHelpCategoryId=1656455518364344322002",cindex:"1656455518364344322002" },
        // { id: 4, name: "成功案例", url: "" },
        // { id: 5, name: "供应链协同", url: "" },
        // { id: 6, name: "政府招标", url: "" },
        // { id: 7, name: "易造学院", url: "" },
      ],
    };
  },
};
</script>