<template>
  <div class="r-ad-list">
    <template v-for="(item,index) in dataList">
      <div class="item" v-if="index<maxLen" :key="item.id" @click="goDetail(item)">
        <div class="t">
          <h3>{{ item.adName }}</h3>
          <p>{{ item.remarks }}</p>
        </div>
        <el-image :src="item.addFiles" lazy></el-image>
        <div class="info">
          <div class="price">￥{{ item.price  }}</div>
          <div class="title">
            {{ item.adContent }}
          </div>
         
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    mode: {
      default: "supplier",
      type: String,
    },
    maxLen: {
      default: 10,
      type: Number,
    },
  },
  data() {
    return {
      dataList: [],
    };
  },
  methods: {
    goDetail(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = item.adUrl;
    },
    goChat(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = "https://www.huixianggongye.com/f/contactKefu?ad_id=" + item.id;
    },
  },
  mounted() {
    this.$sapi.get("/api/v1/getAdList-4.json").then((res) => {
      this.dataList = res.list;
    });
  },
};
</script>